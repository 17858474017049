import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure
} from "@chakra-ui/react"
import React, {Dispatch, SetStateAction, useContext, useState} from "react"
import TournamentSignUpForm from "../forms/TournamentSignUpForm/TournamentSignUpForm";
import SignUpIcon from "../icons/SignUpIcon";
import {TournamentContext, UserContext} from "../../contexts/main";
import TournamentDropOutButton from "./TournamentDropOutButton";

function TournamentSignUpButton(props: {
    onSubmitHandler: Dispatch<SetStateAction<number>>
}) {
    const {onSubmitHandler} = props
    const { isOpen, onOpen, onClose } = useDisclosure()
    const user = useContext(UserContext)
    const [tournament, updateTournament] = useState(useContext(TournamentContext))
    const [playerCount, setPlayerCount] = useState(tournament.relationships.participants.links.meta.count)
    const firstField = React.useRef() as unknown as any
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isRegistered, setRegistered] = useState(
        tournament.relationships.participants.links.data
            .some((player: Participant) => player.attributes.misc === user.usr)
    )

    return (
        <>
            {
                !!user ?
                    <>
                        {
                            (!isRegistered) ?
                                <Button leftIcon={<SignUpIcon/>} colorScheme='teal' onClick={onOpen}>
                                    Join
                                </Button>
                                :
                                <TournamentDropOutButton onSubmitHandler={() => {
                                    const updatedTournament = {
                                        ...tournament,
                                        relationships: {
                                            participants: {
                                                links: {
                                                    ...tournament.relationships.participants.links,
                                                    meta: { count: playerCount }
                                                }
                                            }
                                        }
                                    } as Tournament

                                    onSubmitHandler(playerCount - 1)
                                    setPlayerCount(playerCount - 1)
                                    updateTournament(Object.assign(
                                        { ...tournament },
                                        { ...updatedTournament }
                                    ))
                                    setIsSubmitting(false)
                                    setRegistered(false)
                                }}/>
                        }
                    </> : <></>
            }
            <Drawer
                isOpen={isOpen}
                placement='right'
                initialFocusRef={firstField}
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        Register &mdash; {tournament.attributes.name}
                    </DrawerHeader>

                    <DrawerBody>
                        <TournamentSignUpForm includeSubmit={false} onSubmit={(status: boolean) => {
                            setRegistered(status)
                            onSubmitHandler(playerCount + 1)
                            setPlayerCount(playerCount + 1)
                            onClose()
                        }}/>
                    </DrawerBody>

                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme='green'
                            form="tournament-registration"
                            type="submit"
                            isLoading={isSubmitting}
                        >
                            Sign up
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default TournamentSignUpButton
