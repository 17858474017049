import React from 'react';
import './App.css';
import {Box, ChakraProvider, defineStyle, defineStyleConfig, extendTheme} from "@chakra-ui/react";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import APP_ROUTES from "./routes";

const router = createBrowserRouter([
    ...APP_ROUTES
]);

const thick = defineStyle({
    borderWidth: '5px', // change the width of the border
    borderStyle: "solid", // change the style of the border
    borderRadius: 10, // set border radius to 10
})

export const dividerTheme = defineStyleConfig({
    variants: { thick },
})

const theme = extendTheme({
    colors: {
        brand: {
            100: "#f7fafc",
            900: "#1a202c",
        },
    },
    components: {
        Divider: dividerTheme
    }
})

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box className="App" height="100vh" overflow="scroll">
          <RouterProvider router={router} />
      </Box>
    </ChakraProvider>
  );
}

export default App;
