import {Icon} from "@chakra-ui/react";

const TournamentsIcon = () => {
    return (
        <Icon viewBox='0 0 24 24'>
            <path fill="currentColor" d="M9 2H2v2h5v4H2v2h7V7h5v10H9v-3H2v2h5v4H2v2h7v-3h7v-6h6v-2h-6V5H9z"/>
        </Icon>
    )
}

export default TournamentsIcon