import Login from "./views/Login";
import {getGamesList, verifyJWT} from "./utility";
import TournamentsList from "./views/tournaments/TournamentsList";
import TournamentView from "./views/tournaments/TournamentView";
import {defer, Navigate} from "react-router-dom";
import AuthenticatedWrapper from "./components/layout/AuthenticatedWrapper";
import PrivacyPolicy from "./views/PrivacyPolicy";
import ComingSoon from "./views/ComingSoon";
import TournamentsCreateForm from "./views/tournaments/TournamentsCreateForm";
import NewAccountForm from "./components/forms/NewAccountForm";
import HomePage from "./views/HomePage";
import DashboardLayout from "./components/layout/DashboardLayout";

const APP_ROUTES = [
    {
        path: "/",
        element: <HomePage />,
        loader: async () => {
            const params = new URLSearchParams()
            params.append("page", `1`)
            params.append("per_page", `10`)
            params.append("state", ``)
            params.append("include_participants", ``)
            return defer({
                user: await verifyJWT(),
                tournaments: await fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments?${params.toString()}`).then((res) => res.json()),
                gamesList: await getGamesList(),
            })
        }
    },
    {
        path: "/sign-up",
        element: <NewAccountForm />
    },
    {
        path: "/schedule",
        element: <AuthenticatedWrapper><DashboardLayout><ComingSoon /></DashboardLayout></AuthenticatedWrapper>,
        loader: async () => {
            const params = new URLSearchParams()
            params.append("page", `1`)
            params.append("per_page", `10`)
            params.append("state", ``)
            params.append("include_participants", ``)
            return defer({
                user: await verifyJWT(),
                tournaments: await fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments?${params.toString()}`).then((res) => res.json()),
                gamesList: await getGamesList(),
            })
        }
    },
    {
        path: "/tournaments",
        element: <AuthenticatedWrapper><TournamentsList/></AuthenticatedWrapper>,
        loader: async () => {
            return defer({
                user: await verifyJWT(),
                tournaments: fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments?include_participants`).then((res) => res.json()),
                gamesList: await getGamesList(),
            })
        },
    },
    {
        path: "/tournaments/create",
        element: <TournamentsCreateForm />,
        loader: async () => {
            return defer({
                user: await verifyJWT()
            })
        }
    },
    {
        path: '/tournament/view',
        element: <AuthenticatedWrapper><TournamentView/></AuthenticatedWrapper>,
        //@ts-ignore
        loader: async ({params}) => {
            const tournamentPromise = fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${params.tournamentId}`).then((res) => res.json())
            return defer({
                user: await verifyJWT(),
                tournament: await tournamentPromise,
                gamesList: await getGamesList(),
            })
        },
        children: [{ path: ':tournamentId' }]
    },
    {
        path: "/login",
        element: <Login/>,
        errorElement: <Navigate to="/" replace={true}/>,
        loader: async () => {
            return defer({
                user: await verifyJWT(),
                tournaments: fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments`).then((res) => res.json())
            })
        },
    },
    {
        path: "/logout",
        element: <></>,
        loader: () => {
            localStorage.removeItem("token")
            window.location.href = `${window.location.protocol}//${window.location.host}`
            return null
        }
    },
    {
        path: "/tos",
        element: <ComingSoon/>
    },
    {
        path: "/privacy",
        element: <PrivacyPolicy/>
    },
]

export default APP_ROUTES
