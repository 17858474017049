import DiscordLogin from "./DiscordLogin";
import ChallongeLogin from "./ChallongeLogin";
import TwitterLogin from "./TwitterLogin";
import {Wrap, WrapItem} from "@chakra-ui/react";
import StartggLogin from "./StartggLogin";

const SingleSignOn = () => {
    return (
        <Wrap w="100%">
            <WrapItem><DiscordLogin/></WrapItem>
            <WrapItem><ChallongeLogin/></WrapItem>
            <WrapItem><TwitterLogin/></WrapItem>
            <WrapItem><StartggLogin/></WrapItem>
        </Wrap>
    )
}

export default SingleSignOn
