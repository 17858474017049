import {Container} from "@chakra-ui/react";
import TournamentsList from "./tournaments/TournamentsList";

const HomePage = () => {
    return (
        <Container maxW="100vw" maxH="100vh">
            <TournamentsList/>
        </Container>
    )
}

export default HomePage
