import {
    AbsoluteCenter,
    AspectRatio,
    Box,
    Container, Divider,
    Heading, HStack,
    Image,
    Link as ChakraLink,
    Tag,
    Text
} from "@chakra-ui/react";
import {Await, Link as ReactRouterLink, useLoaderData} from "react-router-dom";
import Preloader from "../Preloader";
import React, {useContext, useState} from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import {TournamentContext, UserContext} from "../../contexts/main";
import TournamentDropOutButton from "../../components/buttons/TournamentDropOutButton";
import TournamentInfo from "../../components/layout/tournaments/TournamentInfo";
import prettyMilliseconds from "pretty-ms";

const TournamentView = () => {
    const data = useLoaderData() as {tournament: Tournament, user: any}
    const user = useContext(UserContext)
    const gamesList: GamesList = (data as any).gamesList
    const [isRegistered, setRegistered] = useState(false)
    const [playerCount, setPlayerCount] = useState(0)

    return (
        <React.Suspense fallback={<Preloader/>}>
            <Await
                resolve={(data as any).tournament}
                errorElement={
                    <DashboardLayout>
                        <AbsoluteCenter>
                            <Text>
                                Hmm, this tournament doesn't seem to exist. <ChakraLink color='yellow.500' as={ReactRouterLink} to="/tournaments">Check out all tournaments instead.</ChakraLink>
                            </Text>
                        </AbsoluteCenter>
                    </DashboardLayout>
                }
            >
                {(tournament: Tournament) => {
                    setRegistered(tournament.relationships.participants.links.data
                        .some((player: Participant) => player.attributes.misc === user.usr))
                    setPlayerCount(tournament.relationships.participants.links.meta.count)

                    const bannerImg = `/images/banners/${tournament.relationships.game.data!.id}`
                    // @ts-ignore
                    const duration = prettyMilliseconds(new Date(tournament.attributes.timestamps.completedAt!) - new Date(tournament.attributes.timestamps.startedAt!), {compact: true})

                    return (
                        <TournamentContext.Provider value={tournament}>
                            <DashboardLayout>
                                <Box
                                    overflow="hidden"
                                    alignItems="center"
                                    boxShadow={`inset 0px -${300 * (0)}px ${300 * (7/10)}px rgb(0,0,0)`}
                                    pos="relative">
                                    <AspectRatio
                                        ratio={16 / 9}
                                        height={300}>
                                        <Image
                                            src={`${bannerImg}.png`}
                                            fallbackSrc={`default.png`}
                                            filter='auto'
                                            maxW="100%"
                                            pos="absolute"
                                            top={0}
                                            left={0}
                                            zIndex={-1}/>
                                    </AspectRatio>
                                    <Box pos="absolute" bottom={0} py={5}>
                                        <Heading
                                            color='white'
                                            mx={4}
                                            fontSize="3em"
                                            textShadow='4px 2px rgb(20,20,20)'
                                            lineHeight={1}>
                                            {tournament!.attributes.name}
                                        </Heading>
                                        <Divider m={4} />
                                        <Tag ml={4}>{gamesList.find((game) => tournament.relationships.game.data!.id === game.game_id)!.game_name}</Tag>
                                        <Tag ml={4}>Status: {tournament.attributes.state.toUpperCase()} {tournament.attributes.state === "complete" ? `(${duration})` : ''}</Tag>
                                    </Box>
                                </Box>
                                <Container maxW='100%' mx={0} px={0} bgColor="#FFFFFF">
                                    <TournamentInfo/>
                                </Container>
                            </DashboardLayout>
                        </TournamentContext.Provider>
                    )
                }}
            </Await>
        </React.Suspense>
    )
}

export default TournamentView
