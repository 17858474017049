import {Box, Button, Center, Image, Link, LinkOverlay, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import GroupIcon from "../../icons/GroupIcon";
import {TournamentContext, UserContext} from "../../../contexts/main";
import React, {useContext, useState} from "react";
import PlayersList from "./PlayersList";
import TournamentSignUpForm from "../../forms/TournamentSignUpForm/TournamentSignUpForm";
import TournamentDropOutButton from "../../buttons/TournamentDropOutButton";
import TournamentResults from "./Results";
import NextMatch from "./NextMatch";
import {Icon} from "@iconify/react";

const TournamentInfo = () => {
    const tournament = useContext(TournamentContext)
    const isComplete = tournament.attributes.state.toLowerCase() === "complete"
    const isInProgress = tournament.attributes.state.toLowerCase() === "underway"
    const user = useContext(UserContext)
    const isRegistrationOpen = !isInProgress && !isComplete
    const isFinalized = tournament.attributes.state === "complete"
    const [isRegistered, setRegistered] = useState(
        tournament.relationships.participants.links.data.some((player: Participant) => player.attributes.misc === user.usr)
    )
    const [playerCount, setPlayerCount] = useState(tournament.relationships.participants.links.meta.count)

    return (
        <TournamentContext.Provider value={tournament}>
            <Tabs isFitted>
                <TabList>
                    <Tab><Icon fontSize="1.5em" icon="wpf:group"/> &nbsp; Players</Tab>
                    <Tab><Icon fontSize="1.5em" icon="mdi:bracket"/> &nbsp; Bracket</Tab>
                    <Tab verticalAlign="middle">
                        <Icon fontSize="2em" icon="game-icons:podium"/> &nbsp; Results
                    </Tab>
                    <Tab><Icon fontSize="1.5em" icon="medical-icon:registration"/> &nbsp; Register</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <PlayersList/>
                    </TabPanel>
                    <TabPanel>
                        {
                            playerCount ?
                                <Box>
                                    <NextMatch/>
                                    <Link isExternal href={`https://challonge.com/${tournament.attributes.url}`}>
                                        <Button variant='ghost' colorScheme='orange'>
                                            View on Challonge.com
                                        </Button>
                                    </Link>
                                    <Link isExternal href={tournament!.attributes.liveImageUrl}>
                                        <Image src={tournament!.attributes.liveImageUrl} />
                                    </Link>
                                </Box>
                                : <Center>Bracket preview unavailable.</Center>
                        }
                    </TabPanel>
                    <TabPanel>
                        <>
                            {
                                isFinalized
                                    ? <TournamentResults/>
                                    : <Center>Results are not available yet.</Center>
                            }
                        </>
                    </TabPanel>
                    <TabPanel>
                        <>
                            {
                                isRegistrationOpen ?
                                    !isRegistered ?
                                    <TournamentSignUpForm onSubmit={() => setRegistered(true)} includeSubmit={true}/>
                                    : <Box my={4} display="flex" justifyContent="center">
                                        <TournamentDropOutButton onSubmitHandler={() => setRegistered(false)}/>
                                    </Box>
                                : <Center>Registration is closed.</Center>
                            }
                        </>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </TournamentContext.Provider>
    )
}

export default TournamentInfo
