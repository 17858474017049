import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    ListItem,
    Stack,
    UnorderedList
} from "@chakra-ui/react";
import {Form, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Icon} from "@iconify/react";

const NewAccountForm = () => {
    const firstField = React.useRef() as unknown as any
    const [userName, setUserName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [password, setPassword] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [show, setShow] = React.useState(false)
    const navigate = useNavigate();
    const handleClick = () => setShow(!show)
    const nameChangeEvent = (event: any) => setUserName(event.target.value)
    const emailChangeEvent = (event: any) => setEmailAddress(event.target.value)
    const passwordChangeEvent = (event: any) => setPassword(event.target.value)

    // @ts-ignore
    const formAction = (event) => {
        event.preventDefault()
        setIsSubmitting(true)

        fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/entrance/signup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ userName, emailAddress, password })
        }).then(async (response) => {
            if (response.ok) {
                fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/entrance/login`, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ emailAddress, password })
                }).then((response) => {
                    if (response.ok) window.location.href = "/"
                })
            }
        }).finally(() => setIsSubmitting(false))
    }

    return (
        <Form id="sign-up" onSubmit={formAction}>
            <Stack spacing='24px'>
                <Box mt={8}>
                    <FormControl>
                        <FormLabel htmlFor='username' display="flex" dir="row" alignItems="center">
                            <Icon icon="bx:at"/> &nbsp; Name
                        </FormLabel>
                        <Input
                            ref={firstField}
                            id='username'
                            value={userName}
                            onChange={nameChangeEvent}
                            required
                        />
                        {/* It is important that the Label comes after the Control due to css selectors */}
                        <FormHelperText>Your display name.</FormHelperText>
                        <FormErrorMessage>You display name is invalid</FormErrorMessage>
                    </FormControl>
                </Box>

                <Box>
                    <FormControl>
                        <FormLabel htmlFor='email' display="flex" dir="row" alignItems="center">
                            <Icon icon="ic:baseline-email"/> &nbsp; Email
                        </FormLabel>
                        <InputGroup>
                            <Input
                                type='email'
                                id='email'
                                placeholder='youremail@example.com'
                                onChange={emailChangeEvent}
                                required
                            />
                        </InputGroup>
                    </FormControl>
                </Box>

                <Box>
                    <FormControl>
                        <FormLabel htmlFor='password' display="flex" dir="row" alignItems="center">
                            <Icon icon="mdi:form-textbox-password"/> &nbsp; Password
                        </FormLabel>
                        <InputGroup size='md'>
                            <Input
                                id='password'
                                pr='4.5rem'
                                type={show ? 'text' : 'password'}
                                placeholder='Enter password'
                                onChange={passwordChangeEvent}
                                required
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick}>
                                    {show ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <FormHelperText>
                            <UnorderedList>
                                <ListItem>Must be 8-16 characters long.</ListItem>
                                <ListItem>Must include at least 1 special character.</ListItem>
                            </UnorderedList>
                        </FormHelperText>
                    </FormControl>
                </Box>

                <Button
                    colorScheme='green'
                    type="submit"
                    isLoading={isSubmitting}>
                    Sign up
                </Button>
            </Stack>
        </Form>
    )
}

export default NewAccountForm
