import React from "react";
import {Await, Navigate, useLoaderData} from "react-router-dom";
import Preloader from "../../views/Preloader";
import {UserContext} from "../../contexts/main";

const AuthenticatedWrapper = ({
    // @ts-ignore
    children
}) => {
    const data = useLoaderData()
    return (
        <React.Suspense fallback={<Preloader/>}>
            <Await resolve={(data as any).user}>
                {(user) => {
                    if (user !== undefined) {
                        return (
                            <UserContext.Provider value={user}>
                                {children}
                            </UserContext.Provider>
                        )
                    } else return <Navigate to="/login"/>
                }}
            </Await>
        </React.Suspense>
    )
}

export default AuthenticatedWrapper