import {
    AbsoluteCenter,
    Box,
    Center,
    Container,
    Divider,
    Grid,
    Image,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from "@chakra-ui/react";
import React from "react";
import SingleSignOn from "../components/SSO/SingleSignOn";
import NewAccountForm from "../components/forms/NewAccountForm";
import LoginForm from "../components/forms/LoginForm";

const Login = () => {
    return (
        <Grid templateColumns="4fr 8fr" autoFlow="column dense" sx={{height: '100vh'}}>
            <Box display="flex" flexDir="column" alignItems="center" justifyContent="center" px={2}>
                <Container maxW="2xl">
                    <Tabs isFitted variant='enclosed-colored'>
                        <TabList mb='1em'>
                            <Tab>Login</Tab>
                            <Tab>Sign Up</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <LoginForm/>
                                <Box position='relative' padding='10'>
                                    <Divider borderColor="black" />
                                    <AbsoluteCenter py={3} bg='black' border="1px solid" px='4' borderRadius={4}>
                                        <Box color="white">OR</Box>
                                    </AbsoluteCenter>
                                </Box>
                                <SingleSignOn />
                            </TabPanel>
                            <TabPanel>
                                <NewAccountForm/>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Container>
            </Box>
            <Box
                backgroundImage="url('/images/moonlight-tavern-bg.png')"
                backgroundRepeat="repeat">
                <Center>
                    <Image src="/images/TMA-logo.png" maxW="100%" maxH="100vh" />
                </Center>
            </Box>
        </Grid>
    )
}

export default Login
