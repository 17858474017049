import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Divider,
    Heading,
    Image,
    LinkBox,
    LinkOverlay,
    List,
    ListIcon,
    ListItem, Skeleton,
    Stack
} from "@chakra-ui/react";
import MarkerIcon from "../../icons/MarkerIcon";
import GroupIcon from "../../icons/GroupIcon";
import CalendarIcon from "../../icons/CalendarIcon";
import TournamentStateBadge from "../../badges/TournamentStateBadge";
import GameIcon from "../../icons/GameIcon";
import {TournamentContext, UserContext} from "../../../contexts/main";
import React, {useState} from "react";
import TournamentSignUpButton from "../../buttons/TournamentSignUpButton";
import {useLoaderData} from "react-router-dom";

const TournamentCard = (props: {
    tournament: Tournament,
}) => {
    const data = useLoaderData()
    const { tournament } = props
    const img = "https://media.discordapp.net/attachments/1118347971822637086/1157887192434221127/dnfn_wallpaper.png?ex=65bf097b&is=65ac947b&hm=c0939ff37a3a3a0cb86eb88655c08d9c2befe80050e9f846d3b66e95834a36d1&=&format=webp&quality=lossless&width=992&height=558"
    const isComplete = tournament.attributes.state.toLowerCase() === "complete"
    const isInProgress = tournament.attributes.state.toLowerCase() === "underway"
    const isRegistrationOpen = !isInProgress && !isComplete && (data as any).user !== undefined
    const [playerCount, setPlayerCount] = useState(tournament.relationships.participants.links.meta.count)

    return (
        <TournamentContext.Provider value={tournament}>
            <Card maxW='sm' variant="outline">
                <LinkBox>
                    <LinkOverlay href={`/tournament/view/${tournament.id}`}>
                        <CardBody>
                            <Skeleton height={200} width={340}>
                                <Image
                                    src={img}
                                    alt={tournament.attributes.name}
                                    fallbackSrc=""
                                />
                            </Skeleton>

                            <Box sx={{transform: "translate(0%, -100%)"}}>
                                <TournamentStateBadge state={tournament.attributes.state}/>
                            </Box>

                            <Stack spacing='3' p={3}>
                                <Heading size='md'>
                                    {tournament.attributes.name}
                                </Heading>

                                <List spacing={1} color="gray.500">
                                    <ListItem>
                                        <Box display="inline-block" mr={1}>
                                            <ListIcon as={GameIcon}/>
                                        </Box>
                                        {tournament.attributes.gameName}
                                    </ListItem>
                                    <ListItem>
                                        <Box display="inline-block" mr={1}>
                                            <ListIcon as={CalendarIcon}/>
                                        </Box>
                                        {new Date(tournament.attributes.timestamps.startsAt).toDateString()}
                                    </ListItem>
                                    <ListItem>
                                        <Box display="inline-block" mr={1}>
                                            <ListIcon as={MarkerIcon}/>
                                        </Box>
                                        Online
                                    </ListItem>
                                    <ListItem>
                                        <Box display="inline-block" mr={1}>
                                            <ListIcon as={GroupIcon}/>
                                        </Box>
                                        {playerCount}
                                    </ListItem>
                                </List>
                            </Stack>
                        </CardBody>
                    </LinkOverlay>
                </LinkBox>

                <Divider colorScheme="gray"/>

                <CardFooter>
                    <ButtonGroup spacing='2'>
                        {
                            !!(data as any).user ?
                                <UserContext.Provider value={(data as any).user}>
                                    {
                                        isRegistrationOpen ?
                                            <TournamentSignUpButton onSubmitHandler={setPlayerCount}/> :
                                            <></>
                                    }
                                </UserContext.Provider> :
                                <></>
                        }
                        <LinkBox>
                            <LinkOverlay isExternal
                                         href={`https://challonge.com/${props.tournament.attributes.url}`}>
                                <Button variant='ghost' colorScheme='blue'>
                                    Details
                                </Button>
                            </LinkOverlay>
                        </LinkBox>
                    </ButtonGroup>
                </CardFooter>
            </Card>
        </TournamentContext.Provider>
    )
}

export default TournamentCard
