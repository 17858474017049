import {
    Avatar,
    Box, Button,
    Card, CardBody, CardFooter, CardHeader, Container,
    HStack, ListItem,
    OrderedList,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td, Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import React, {useContext, useState} from "react";
import {TournamentContext, UserContext} from "../../../contexts/main";
import {Simulate} from "react-dom/test-utils";
import play = Simulate.play;
import {Icon} from "@iconify/react";

const NextMatch = () => {
    const tournament = useContext(TournamentContext)
    const user = useContext(UserContext)
    const [matches, setMatches] = useState(tournament.relationships.matches.links.data as unknown as Match[])
    const [players, setPlayers] = useState(tournament.relationships.participants.links.data as unknown as Participant[])
    const player = tournament.relationships.participants.links.data.find((player: Participant) => player.attributes.misc === user.usr)
    const isPlayer1 = !!matches.find((match) => match.relationships?.player1?.data.id === player?.id)
    const findPlayerInfo = (playerId: string | number) => players.find((player) => `${player.id}` === `${playerId}`)
    const [playerMatches, setPlayerMatches] = useState(
        matches.filter((match) =>
            [match.relationships?.player1?.data.id, match.relationships?.player2?.data.id].includes(player?.id)
            && ["open", "pending"].includes(`${match.attributes.state}`)
        )
    )

    return (
        <>
            {
                playerMatches.map((match) => {
                    const opp = isPlayer1 ? findPlayerInfo(match.relationships?.player2?.data.id!) : findPlayerInfo(match.relationships?.player1?.data.id!)
                    return (
                        <Container
                            centerContent
                            maxW="2xl" p={3}
                            variant="outline"
                            bg="orange.500"
                            color="white"
                            rounded={10}>
                            <Text fontWeight="bold" fontSize="large">YOUR NEXT MATCH</Text>
                            <Text lineHeight={1} fontSize="xxx-large">vs {opp?.attributes.name}</Text>
                            <Button mt={3} py={2} px={3} pl={2} size="2xl" colorScheme='green'>
                                <Icon icon="clarity:check-circle-solid" fontSize="2em"/> Check-in
                            </Button>
                        </Container>
                    )
                })
            }
        </>
    )
}

export default NextMatch
