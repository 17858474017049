import AuthenticatedWrapper from "../../components/layout/AuthenticatedWrapper";
import DashboardLayout from "../../components/layout/DashboardLayout";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {Button, Container, FormControl, FormErrorMessage, FormLabel, Input, Select} from "@chakra-ui/react";
import GameIcon from "../../components/icons/GameIcon";
import {useNavigate} from "react-router-dom";

const TournamentsCreateForm = () => {
    const navigate = useNavigate()
    const GAME_LIST = [
        "DNF Duel",
        "TEKKEN 8",
        "Granblue Fantasy Versus: Rising",
        "UNDER NIGHT IN-BIRTH II Sys:Celes",
        "GUILTY GEAR -STRIVE-",
        "DRAGON BALL FighterZ",
        "BlazBlue Centralfiction"
    ]
    const createForm = async (
        values: TournamentFormValues,
        {setSubmitting}: FormikHelpers<TournamentFormValues>
    ) => {
        const request = await fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/create`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: values.name,
                game_name: values.game_name,
                tournament_type: values.tournament_type,
                starts_at: values.starts_at,
                registration_options: {
                    check_in_duration: values.check_in_duration
                }
            } as TournamentPayload)
        })

        if (request.ok) {
            setSubmitting(false)
            navigate("/tournaments")
        } else {
            console.error(`Something went wrong: ${request.statusText}`)
        }
    }

    return (
        <AuthenticatedWrapper>
            <DashboardLayout>
                <Container p={4}>
                    <Formik initialValues={{
                        name: "",
                        game_name: GAME_LIST[0],
                        tournament_type: 'double elimination',
                        starts_at: new Date().toISOString(),
                        check_in_duration: 60,
                    }} onSubmit={createForm}>
                        {(props) => (
                            <Form>
                                <Field name="name">
                                    {/*@ts-ignore*/}
                                    {({field, form}) => (
                                        <FormControl isInvalid={form.errors.name && form.touched.name}>
                                            <FormLabel>Tournament Name</FormLabel>
                                            <Input {...field} placeholder="Tournament Name" isRequired/>
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="starts_at">
                                    {/*@ts-ignore*/}
                                    {({field, form}) => (
                                        <FormControl isInvalid={form.errors.name && form.touched.name}>
                                            <FormLabel>Start Date/Time</FormLabel>
                                            <Input
                                                {...field}
                                                size="md"
                                                type="datetime-local"
                                                isRequired
                                            />
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="check_in_duration">
                                    {/*@ts-ignore*/}
                                    {({field, form}) => (
                                        <FormControl isInvalid={form.errors.name && form.touched.name}>
                                            <FormLabel>Check-in Start</FormLabel>
                                            <Input
                                                {...field}
                                                size="md"
                                                type="number"
                                                isRequired
                                            />
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="tournament_type">
                                    {/*@ts-ignore*/}
                                    {({field, form}) => (
                                        <FormControl isInvalid={form.errors.name && form.touched.name}>
                                            <FormLabel>Tournament Type</FormLabel>
                                            <Select {...field} isRequired>
                                                <option value='single elimination'>Single Elimination</option>
                                                <option value='double elimination'>Double Elimination</option>
                                                <option value='round robin'>Round Robin</option>
                                                <option value='swiss'>Swiss</option>
                                                <option value='free for all'>Free For All</option>
                                            </Select>
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="game_name">
                                    {/*@ts-ignore*/}
                                    {({field, form}) => (
                                        <FormControl isInvalid={form.errors.name && form.touched.name}>
                                            <FormLabel>Game</FormLabel>
                                            <Select icon={<GameIcon/>} {...field} isRequired variant="filled">
                                                {[...GAME_LIST].map((game: string, index) => (
                                                    <option value={game} key={index}>{game}</option>
                                                ))}
                                            </Select>
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Button
                                    mt={4}
                                    colorScheme='teal'
                                    isLoading={props.isSubmitting}
                                    type='submit'
                                >
                                    Submit
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </DashboardLayout>
        </AuthenticatedWrapper>
    )
}

export default TournamentsCreateForm
