export const verifyJWT = () => {
    const url = new URL(window.location.href)
    const jwt = url.searchParams.get("jwt")
    if (jwt) {
        localStorage.setItem("token", jwt)
        window.location.replace(`${window.location.protocol}//${window.location.host}`)
    }

    return fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/verify-jwt`, {
        method: "GET",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
        if (res.status >=200 && res.status <= 400) return res.json()
        else return undefined
    })
}

export const getGamesList = () => {
    return fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/games-list`, {
        method: "GET",
    }).then((res) => res.json())
}
