import {
    AbsoluteCenter,
    Avatar,
    Box, Center, Divider, Heading,
    HStack, ListItem,
    OrderedList,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import React, {useContext, useState} from "react";
import {TournamentContext} from "../../../contexts/main";
import {Simulate} from "react-dom/test-utils";
import play = Simulate.play;
import {Icon} from "@iconify/react";

const TournamentResults = () => {
    const tournament = useContext(TournamentContext)
    const [playerCount, setPlayerCount] = useState(tournament.relationships.participants.links.meta.count)
    const [players, setPlayers] = useState(tournament.relationships.participants.links.data as Participant[])
    const top8 =
        players
            .filter((participant) => participant.attributes.finalRank! < 9)
            .sort((a, b) => a.attributes.finalRank! < b.attributes.finalRank! ? -1 : 1)
    const theRest =
        players
            .filter((participant) => participant.attributes.finalRank! >= 9)
            .sort((a, b) => a.attributes.finalRank! < b.attributes.finalRank! ? -1 : 1)

    return (
        <OrderedList>
            <Center><Heading fontSize="5em">TOP 8</Heading></Center>

            <Box position='relative' padding='10'>
                <Divider borderColor="black" variant="thick" />
                <AbsoluteCenter py={3} bg='black' px='4' borderRadius={7}>
                    <Icon fontSize="2em" icon="octicon:feed-trophy-16" color="white"/>
                </AbsoluteCenter>
            </Box>

            <HStack mb={3} justifyContent="space-between">
                {
                    top8.map((player) => {
                        return (
                            <ListItem p={2} fontWeight="bold">{player.attributes.name}</ListItem>
                        )
                    })
                }
            </HStack>

            <Box position='relative' padding='10'>
                <Divider borderColor="black" />
                <AbsoluteCenter py={3} bg='black' border="1px solid" px='4' borderRadius={4}>
                    <Icon icon="fa:chevron-down" color="white" />
                </AbsoluteCenter>
            </Box>

            <Center><Heading fontSize="2em">...and the rest 😒</Heading></Center>

            {
                theRest.map((player) => {
                    return (
                        <ListItem>{player.attributes.name}</ListItem>
                    )
                })
            }
        </OrderedList>
    )
}

export default TournamentResults
