import {Avatar, Box, HStack, Text} from "@chakra-ui/react";
import {useContext, useEffect, useState} from "react";
import Navigation from "./Navigation";
import {UserContext} from "../../contexts/main";

// @ts-ignore
const MenuPanel = () => {
    const authenticatedUser = useContext(UserContext) as unknown as AuthenticatedUser
    const [avatar, setAvatar] = useState(<Avatar/>)
    const [name, setName] = useState("")

    const decodeUser = (userObj: any) => {
        switch (userObj) {
            case "discord":
                const discordUser = JSON.parse(authenticatedUser.msc) as DiscordUser
                return ({
                    avatar: <Avatar src={`https://cdn.discordapp.com/avatars/${discordUser.user.id}/${discordUser.user.avatar}`} name={discordUser.user.global_name} />,
                    name: discordUser.user.global_name,
                })
            case "challonge":
                const challongeUser = JSON.parse(authenticatedUser.msc) as ChallongeUser
                return ({
                    avatar: <Avatar src={challongeUser.data.attributes.imageUrl} name={challongeUser.data.attributes.username} />,
                    name: challongeUser.data.attributes.username,
                })
            case "start.gg":
                const startggUser = JSON.parse(authenticatedUser.msc) as StartggUser
                return ({
                    avatar: startggUser.images.length ?
                        <Avatar src={startggUser.images[startggUser.images.length - 1].url} name={startggUser.name} />
                        : <Avatar name={startggUser.name} />,
                    name: startggUser.name,
                })
            case "twitter":
                const twitterUser = JSON.parse(authenticatedUser.msc) as TwitterUser
                return ({
                    avatar: <Avatar name={twitterUser.data.name} />,
                    name: twitterUser.data.name,
                })
            default:
                return ({
                    avatar: <Avatar name={authenticatedUser.usr} />,
                    name: `${authenticatedUser.usr} (Guest User)`,
                })
        }
    }

    return (
        <>
            {
                !!authenticatedUser ?
                    <HStack p={2} alignItems="center">
                        {decodeUser(authenticatedUser.api)!.avatar}
                        <Text mx={4} color="gray.200">{decodeUser(authenticatedUser.api)!.name}</Text>
                    </HStack>
                    : <></>
            }
            <Navigation/>
        </>
    )
}

export default MenuPanel
