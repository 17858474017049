import { createContext } from 'react';

export const AppConfigContext = createContext({
    DISCORD_CLIENT_ID: process.env.REACT_APP_DISCORD_CLIENT_ID,
    CHALLONGE_CLIENT_ID: process.env.REACT_APP_CHALLONGE_CLIENT_ID,
    START_GG_CLIENT_ID: process.env.REACT_APP_STARTGG_OAUTH_CLIENT_ID,
    TWITTER_CLIENT_ID: process.env.REACT_APP_TWITTER_OAUTH_CLIENT_ID,
    S1_API: process.env.REACT_APP_S1_API,
})

export const UserContext = createContext(undefined as unknown as AuthenticatedUser)
export const TournamentContext = createContext(undefined as unknown as Tournament)