import {Icon} from "@chakra-ui/react";

const LogoutIcon = () => {
    return (
        <Icon viewBox='0 0 24 24'>
            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4.001H5v14a2 2 0 0 0 2 2h8m1-5l3-3m0 0l-3-3m3 3H9"/>
        </Icon>
    )
}

export default LogoutIcon