import {AbsoluteCenter, Container} from "@chakra-ui/react";

const ComingSoon = () => {
    return (
        <Container sx={{height: '100vh', width: '100vw'}}>
            <AbsoluteCenter>Coming Soon...</AbsoluteCenter>
        </Container>
    )
}

export default ComingSoon