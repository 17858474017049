import {Container, Heading, Link, Text, VStack} from "@chakra-ui/react";

const PrivacyPolicy = () => {
    return (
        <Container maxW="container.md" sx={{height: '100vh', width: '100vw'}}>
            <VStack py={4} bg="BlackAlpha.500" sx={{textAlign: "left"}}>
                <Heading>Introduction</Heading>
                <Text>
                    Welcome to mtch.gg (pronounced Match dot GeeGee)! At mtch.gg, we are committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website.
                </Text>

                <Heading>Information We Collect</Heading>
                <Text>
                    Personal Information: When you register on mtch.gg, we may collect personal information such as your name, email address, and other relevant details to provide you with a personalized and enhanced user experience.
                </Text>
                <Text>
                    Usage Information: We may collect information about how you interact with our website, including pages visited, time spent on the site, and other browsing activities.
                </Text>

                <Heading>How We Use Your Information</Heading>
                <Text>Personalization: We use your information to personalize your experience on mtch.gg, providing you with relevant content, features, and recommendations.</Text>
                <Text>Communication: We may use your email address to send you updates, newsletters, and other communications related to mtch.gg. You can opt-out of these communications at any time.</Text>
                <Text>Improvements: Your feedback and usage data help us analyze and enhance the performance, features, and content of mtch.gg.</Text>

                <Heading>Information Sharing and Disclosure</Heading>
                <Text>Third-Party Services: We may use third-party services to facilitate our website and enhance user experience. These services may have their own privacy policies, and we encourage you to review them.</Text>
                <Text>Legal Compliance: We may disclose your information when required by law or in response to a legal request.</Text>
                <Text></Text>

                <Heading>Security</Heading>
                <Text>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</Text>

                <Heading>Your Choices</Heading>
                <Text>You can manage your preferences for receiving communications from mtch.gg by adjusting your account settings or using the unsubscribe link provided in our emails.</Text>

                <Heading>Changes to this Privacy Policy</Heading>
                <Text>mtch.gg reserves the right to update this Privacy Policy at any time. We will notify you of any significant changes by posting the updated policy on our website.</Text>

                <Heading>Contact Us</Heading>
                <Text>If you have any questions or concerns about this Privacy Policy, please contact us at <Link color="gray.500" href="mailto:contact@mtch.gg">contact@mtch.gg</Link></Text>
            </VStack>
        </Container>
    )
}

export default PrivacyPolicy