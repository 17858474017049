import {Button} from "@chakra-ui/react";
import {useContext} from "react";
import {AppConfigContext} from "../../contexts/main";
import TwitterIcon from "../icons/TwitterIcon";

const TwitterLogin = () => {
    const appConfig = useContext(AppConfigContext);
    const redirect = () => {
        const oAuthEndpoint = `https://twitter.com/i/oauth2/authorize`
        const params = new URLSearchParams()
        params.append("response_type", "code")
        params.append("client_id", `${appConfig.TWITTER_CLIENT_ID}`)
        params.append("scope", `tweet.read users.read offline.access`)
        params.append("state", `15773059ghq9183habn`)
        params.append("code_challenge_method", `plain`)

        window.location.href = `${oAuthEndpoint}?${params.toString()}&code_challenge=${btoa("mylittlesecret")}&redirect_uri=${process.env.REACT_APP_S1_API_ENDPOINT}/twitter/oauth`
    }

    return (
        <>
            <Button
                leftIcon={<TwitterIcon/>}
                onClick={redirect}
                colorScheme='gray'>
                Twitter
            </Button>
        </>
    )
}

export default TwitterLogin
