import {Badge} from "@chakra-ui/react";

// @ts-ignore
const TournamentStateBadge = ({state}) => {
    switch (state) {
        case "complete":
            return <Badge colorScheme="red" variant="solid">Registration Closed</Badge>
        case "pending":
            return <Badge colorScheme="green" variant="solid">Registration Open</Badge>
        case "underway":
            return <Badge colorScheme="blue" variant="solid">In Progress</Badge>
        default:
            return (
                <></>
            )
    }
}

export default TournamentStateBadge