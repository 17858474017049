import {HStack, List, ListIcon, ListItem, Text} from "@chakra-ui/react";
import TournamentsIcon from "../icons/TournamentsIcon";
import LogoutIcon from "../icons/LogoutIcon";
import {NavLink} from 'react-router-dom'
import CalendarIcon from "../icons/CalendarIcon";

// @ts-ignore
const MenuLink = ({path, text, icon}) => {
    return (
        <NavLink
            to={`${path}`}
            style={({ isActive, isPending, isTransitioning }) => {
                return {
                    color: isActive || isPending || isTransitioning ? "#FFFFFF" : "inherit",
                    background: "gray.200",
                }
            }}>
            <ListItem
                p={2}
                fontSize="1.25em"
                _hover={{
                background: "gray.200",
                color: "gray.800",
                cursor: "pointer"
            }}>
                <HStack alignItems="center">
                    <ListIcon as={icon}/>
                    <Text>{text}</Text>
                </HStack>
            </ListItem>
        </NavLink>
    )
}

const Navigation = () => {
    return (
        <List color="gray.500">
            <MenuLink path="/schedule" text="Schedule" icon={CalendarIcon}/>
            <MenuLink path="/tournaments" text="Tournaments" icon={TournamentsIcon}/>
            <MenuLink path="/logout" text="Logout" icon={LogoutIcon}/>
        </List>
    )
}

export default Navigation
