import {Box, Button, Select, Stack, Text, Wrap, WrapItem} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Await, useLoaderData} from "react-router-dom";
import TournamentCard from "../../components/layout/tournaments/TournamentCard";
import Preloader from "../Preloader";

const TournamentsList = () => {
    const data = useLoaderData()
    const [filteredTournaments, setFilteredTournaments] = useState([] as Tournament[])
    const gamesList: GamesList = (data as any).gamesList
    const [gamesFilter, setGamesFilter] = useState([] as string[])
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [state, setState] = useState("")
    const [gameId, setGameId] = useState("")
    const [loadingGames, setLoadingGames] = useState(false)
    const [loadedAll, setLoadedAll] = useState(false)

    const filterTournamentsByGame = (tournaments: Tournament[]) => {
        return tournaments
            .filter((tournament) => {
                if (tournament.relationships.game.data) return gamesFilter.includes(tournament.relationships.game.data.id)
                return false
            })
    }

    const deDupeTournaments = (queriedTournaments: Tournament[]) => {
        const tournamentIds = filteredTournaments.map((tournament) => tournament.id)
        const queriedTournamentsIds = queriedTournaments.map((tournament) => tournament.id)
        const uniqueTournaments = queriedTournamentsIds.filter((tournamentId) => !tournamentIds.includes(tournamentId))
        return queriedTournaments.filter((tournament) => uniqueTournaments.includes(tournament.id))
    }

    const loadTournaments = async () => {
        setLoadingGames(true)

        const params = new URLSearchParams()
        params.append("page", `${page + 1}`)
        params.append("per_page", `${perPage}`)
        params.append("state", `${state}`)
        params.append("include_participants", "")

        const request = await fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments?${params.toString()}`)

        if (request.ok) {
            const retrievedTournaments = await request.json()
            if (retrievedTournaments.length) {
                setFilteredTournaments([...filteredTournaments, ...deDupeTournaments(retrievedTournaments)])
                setPage(page + 1)
            } else setLoadedAll(true)
        }

        setLoadingGames(false)
    }

    const gameFilterEvent = async (event: any) => {
        if (!event.target.value) setGamesFilter([...gamesList.map((game) => `${game.game_id}`)])
        else setGamesFilter([event.target.value])
        setGameId(`${event.target.value}`)
    }

    useEffect(() => {
        setGamesFilter([...gamesList.map((game) => `${game.game_id}`)])
        if (data) setFilteredTournaments((data as any).tournaments)
    }, [setGamesFilter])

    return (
        <>
            <Box>
                <Select placeholder='All Games' onChange={gameFilterEvent}>
                    { gamesList.map((game) => (<option key={game.game_id} value={game.game_id}>{game.game_name}</option>))}
                </Select>
            </Box>
            <React.Suspense fallback={<Preloader/>}>
                <Await
                    // @ts-ignore
                    resolve={filteredTournaments as Promise}
                    errorElement={
                        <Text>No Tournaments Found.</Text>
                    }>
                    {
                        (tournaments: Tournament[]) => {
                            return (
                                <Wrap p={2} spacing={4}>
                                    {
                                        filterTournamentsByGame(tournaments).map((tournament) => {
                                            return (
                                                <WrapItem key={tournament.id}>
                                                    <TournamentCard key={tournament.id} tournament={tournament}/>
                                                </WrapItem>
                                            )
                                        })
                                    }
                                </Wrap>
                            )
                        }
                    }
                </Await>
            </React.Suspense>
            <Stack direction='row' p={4} spacing={4} justifyContent="center">
                {
                    !loadedAll ?
                        <Button
                            isLoading={loadingGames}
                            loadingText='Loading...'
                            colorScheme='teal'
                            variant='outline'
                            onClick={loadTournaments}
                        >
                            Load More
                        </Button>
                        :
                        <Text>There are no more tournaments.</Text>
                }
            </Stack>
        </>
    )
}

export default TournamentsList
