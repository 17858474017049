import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    Stack
} from "@chakra-ui/react";
import React, {useContext, useState} from "react";
import {TournamentContext, UserContext} from "../../../contexts/main";
import {Form} from "react-router-dom";

// @ts-ignore
const TournamentSignUpForm = (props: {
    onSubmit: Function,
    includeSubmit: boolean
}) => {
    const {onSubmit, includeSubmit} = props
    const firstField = React.useRef() as unknown as any
    const user = useContext(UserContext)
    const [name, setName] = useState(user.usr)
    const [twitterHandle, setTwitterHandle] = useState(user.usr)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const tournament = useContext(TournamentContext)
    const nameChangeEvent = (event: any) => setName(event.target.value)
    const twitterHandleEvent = (event: any) => setTwitterHandle(event.target.value)

    // @ts-ignore
    const formAction = (event) => {
        event.preventDefault()
        setIsSubmitting(true)

        fetch(`${process.env.REACT_APP_S1_API_ENDPOINT}/tournaments/${tournament.id}/sign-up`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({name, misc: name})
        }).then(async (response) => {
            if (response.ok) onSubmit(true)
            else onSubmit(false)
        }).finally(() => setIsSubmitting(false))
    }

    return (
        <Form id="tournament-registration" onSubmit={formAction}>
            <Stack spacing='24px'>
                <Box mt={8}>
                    <FormControl>
                        <FormLabel htmlFor='username'>Name</FormLabel>
                        <Input
                            ref={firstField}
                            id='username'
                            value={name}
                            onChange={nameChangeEvent}
                            disabled={true}
                        />
                        {/* It is important that the Label comes after the Control due to css selectors */}
                        <FormHelperText>Your display name for the tournament</FormHelperText>
                        <FormErrorMessage>You display name is invalid</FormErrorMessage>
                    </FormControl>
                </Box>

                <Box>
                    <FormControl>
                        <FormLabel htmlFor='twitter'>Twitter Handle</FormLabel>
                        <InputGroup>
                            <InputLeftAddon>@</InputLeftAddon>
                            <Input
                                type='input'
                                id='twitter'
                                placeholder='YourTwitterHandleHere'
                                onChange={twitterHandleEvent}
                            />
                        </InputGroup>
                        <FormHelperText>Optional</FormHelperText>
                    </FormControl>
                </Box>

                {
                    includeSubmit ?
                        <Button
                            colorScheme='green'
                            form="tournament-registration"
                            type="submit"
                            isLoading={isSubmitting}
                        >
                            Sign up
                        </Button>
                        :
                        <></>
                }
            </Stack>
        </Form>
    )
}

export default TournamentSignUpForm
