import {Icon} from "@chakra-ui/react";

const CalendarIcon = () => {
    return (
        <Icon viewBox='0 0 24 24'>
            <path fill="currentColor" d="M2 11h20v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm15-8h4a1 1 0 0 1 1 1v5H2V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2z" />
        </Icon>
    )
}

export default CalendarIcon