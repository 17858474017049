import {Avatar, Box, HStack, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import React, {useContext, useState} from "react";
import {TournamentContext} from "../../../contexts/main";

const PlayersList = () => {
    const tournament = useContext(TournamentContext)
    const [playerCount, setPlayerCount] = useState(tournament.relationships.participants.links.meta.count)
    return (
        <TableContainer>
            <Table variant='striped'>
                <TableCaption>{playerCount} participants</TableCaption>
                <Thead>
                    <Tr>
                        <Th>Player</Th>
                        <Th isNumeric>Seed</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        tournament.relationships.participants.links.data.map((participant) => {
                            return (
                                <Tr>
                                    <Td>
                                        <HStack>
                                            <Avatar name={`${participant.attributes.name}`} />
                                            <Box>{participant.attributes.name}</Box>
                                        </HStack>
                                    </Td>
                                    <Td isNumeric>{participant.attributes.seed}</Td>
                                </Tr>
                            )
                        })
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default PlayersList
