import {Icon} from "@chakra-ui/react";

const SignUpIcon = () => {
    return (
        <Icon viewBox='0 0 24 24'>
            <path fill="currentColor" d="M15 14c-2.67 0-8 1.33-8 4v2h16v-2c0-2.67-5.33-4-8-4m-9-4V7H4v3H1v2h3v3h2v-3h3v-2m6 2a4 4 0 0 0 4-4a4 4 0 0 0-4-4a4 4 0 0 0-4 4a4 4 0 0 0 4 4" />
        </Icon>
    )
}

export default SignUpIcon