import {Button} from "@chakra-ui/react";
import {useContext} from "react";
import {AppConfigContext} from "../../contexts/main";
import DiscordIcon from "../icons/DiscordIcon";

const DiscordLogin = () => {
    const appConfig = useContext(AppConfigContext);
    const redirect = () => {
        const discordOAuthEndpoint = `https://discord.com/oauth2/authorize?response_type=code&client_id=${appConfig.DISCORD_CLIENT_ID}&state=15773059ghq9183habn&scope=identify`
        const redirect_uri = `&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_S1_API_ENDPOINT}/discord/oauth`)}`
        window.location.href = discordOAuthEndpoint + redirect_uri
    }

    return (
        <>
            <Button
                leftIcon={<DiscordIcon />}
                onClick={redirect}
                colorScheme='blue'>
                Discord
            </Button>
        </>
    )
}

export default DiscordLogin
