import {Grid, GridItem} from "@chakra-ui/react";
import MenuPanel from "./MenuPanel";
import React from "react";

// @ts-ignore
const DashboardLayout = ({ children }) => {
    return (
        <Grid templateColumns={{sm: "1fr", md: "2fr 10fr"}} minH="100%">
            <GridItem bg="black">
                <MenuPanel/>
            </GridItem>
            <GridItem>
                {children}
            </GridItem>
        </Grid>
    )
}

export default DashboardLayout
